import { useRouter } from 'next/router';

interface RedirectWithAfter {
  to: string;
  after?: string;
}

interface useRedirectReturn {
  asPath: string;
  redirectUrl: string;
  redirectUser?: ({ to, after }: RedirectWithAfter) => null;
}

export const useRedirect = (): useRedirectReturn => {
  const router = useRouter();
  const { asPath, query } = router;
  const { redirect } = query;

  const redirectUser = ({ to, after }: RedirectWithAfter): null => {
    const parametersToPass = after ? { redirect: encodeURI(after) } : {};
    router.push({
      pathname: to,
      query: parametersToPass,
    });
    return null;
  };

  return {
    asPath: asPath ?? '',
    redirectUser,
    redirectUrl: (redirect as string) ?? undefined,
  };
};
